import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adminUpdateTransaction: {},
    saleUpdateTransaction: {},
    source: {},
  },
  mutations: {
    SET_adminUpdateTransaction (state, value) {
      state.adminUpdateTransaction = value
    },
    SET_saleUpdateTransaction (state, value) {
      state.saleUpdateTransaction = value
    },
    SET_source (state, value) {
      state.source = value
    },
  },
  actions: {
    async login ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/login?user=${
        payload.username || ''
      }&pass=${payload.password || ''}`
      const response = await axios({ url: url }).catch(function (error) {
        console.log(error)
      })
      if (response) {
        const data = response.data
        if (data.code === 'SUCCESS') {
          return data.data
        } else {
          console.log('log in error')
          console.warn(data)
          return 'error'
        }
      }
    },
    async getLeadList ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_lead_list?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getSourceList ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_source_list?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getLeadListAdmin ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_lead_list_admin?uuid=${ payload.uuid }&keyword=${ payload.keyword }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getRemoveReasonAdmin ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_remove_reason_admin?uuid=${ payload.uuid }&keyword=${ payload.keyword }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getAppointmentAdmin ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_appointment_admin?uuid=${ payload.uuid }&keyword=${ payload.keyword }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getSaleListAdmin ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_sale_list_admin?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getDashboardOverview ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_dashboard_overview?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getDashboardSale ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_dashboard_sale?uuid=${ payload.uuid }&sale_id=${ payload.sale_id }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getDashboardSaleSpecific ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_dashboard_sale_specific?uuid=${ payload.uuid }&sale_id=${ payload.sale_id }&month=${ payload.month }&year=${ payload.year }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getSaleListMonth ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_lead_list_month?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getSaleTransaciontAdmin ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_sale_transaction_list_admin?uuid=${ payload.uuid }&keyword=${ payload.keyword }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async customerDetail ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/customer_detail_from_mobile?uuid=${ payload.uuid }&mobile=${ payload.mobile }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async leadMonthInfo ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_lead_list_month_info?uuid=${ payload.uuid }&month=${ payload.month }&source=${ payload.source }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async leadMonthInfoFilter ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_lead_list_admin_month?uuid=${ payload.uuid }&month=${ payload.month }&source=${ payload.source }&status=${ payload.status }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getMobileUsed ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/today_mobile_used?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async changeStatusToZ ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/transaction_change_status_to_z?uuid=${ payload.uuid }&id=${ payload.id }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async make_appointment ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/make_appointment?uuid=${ payload.uuid }&mobile=${ payload.mobile }&from_date=${ payload.from }&to_date=${ payload.to }&note=${ payload.note }&choice=${ payload.choice }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async assign_mobile_to_sale ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/assign_mobile_to_sale?uuid=${ payload.uuid }&mobile=${ payload.mobile }&sale_id=${ payload.sale_id }&date=${ payload.date }&status=${ payload.status }&source=${ payload.source }&note=${ payload.note }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async assign_mobile_to_sale_admin ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/assign_mobile_to_sale?uuid=${ payload.uuid }&mobile=${ payload.mobile }&sale_id=${ payload.sale_id }&date=${ payload.date }&status=${ payload.status }&note_admin=${ payload.note }&source=${ payload.source }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async assign_mobile_to_sale_multi ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/post/assign_mobile_to_sale_multi`
      const params = payload
      const response = await axios.post(url, params)
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async assign_work_manual ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/assign_work_manual?uuid=${ payload.uuid }&work_count=${ payload.work_count }&sale_id=${ payload.sale_id }&source=${ payload.source }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async assign_work_manual_month ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/assign_work_manual_month?uuid=${ payload.uuid }&work_count=${ payload.work_count }&sale_id=${ payload.sale_id }&month=${ payload.month }&source=${ payload.source }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async resetDAndAssignCron ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_reset_d_and_run_cron?uuid=${ payload.uuid }&current_work=${ payload.current_work }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async saleChangeStatusToA ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/sale_change_status_to_a?uuid=${ payload.uuid }&mobile=${ payload.mobile }&remove_reason=${ payload.remove_reason }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async saleChangeStatusToI ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/sale_change_status_to_i?uuid=${ payload.uuid }&mobile=${ payload.mobile }&remove_reason=${ payload.remove_reason }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async createTransaction ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/post/create_transaction`
      const params = payload
      const response = await axios.post(url, params)
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async updateTransaction ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/post/update_transaction`
      const params = payload
      const response = await axios.post(url, params)
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async getMobileAvailable ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/get_mobile_available?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async addLeadManual ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/add_lead_manual`
      const params = payload
      const response = await axios.post(url, params)
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async importLead ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/import_excel_multi_source`
      const params = payload
      const response = await axios.post(url, params)
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async exportCreateTransactionCSV ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/export_create_transaction_csv?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async exportCreateTransactionCSVSuperAdmin ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/export_create_transaction_csv_super_admin?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async exportCreateTransactionCSVSuperAdminDate ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/export_create_transaction_csv_super_admin?uuid=${ payload.uuid }&date=${ payload.date }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async exportDataPoolACSVSuperAdmin ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/export_datapool_a_csv_super_admin?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
    async exportDataPoolPSCSV ({ state, commit, dispatch }, payload) {
      const url = `${process.env.VUE_APP_WS_BASE}/ws/get/export_data_pool_ps_csv?uuid=${ payload.uuid }`
      const response = await axios({ url: url })
      const data = response.data
      if (data.code === 'SUCCESS') {
        return data.data
      } else {
        console.warn(data)
        return {}
      }
    },
  },
  modules: {
  }
})
