import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    beforeEnter: checkLoginAuth,
  },
  {
    path: '/end',
    name: 'End',
    component: () => import(/* webpackChunkName: "about" */ '../views/End.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/sale',
    name: 'Sale',
    component: () => import(/* webpackChunkName: "about" */ '../views/SaleManager.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/createTransaction',
    name: 'CreateTransaction',
    component: () => import(/* webpackChunkName: "about" */ '../views/CreateTransaction.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/addDataPool',
    name: 'AddDataPool',
    component: () => import(/* webpackChunkName: "about" */ '../views/AddDataPool.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/updateTransactionAdmin',
    name: 'UpdateTransactionAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/UpdateTransactionAdmin.vue'),
    beforeEnter: checkAuth,
  },
  {
    path: '/superAdmin',
    name: 'SuperAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuperAdmin.vue'),
    beforeEnter: checkAuth,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

function checkAuth(to, from, next) {
  console.log('checkAuth')
  let userCookie = Vue.cookie.get('user')
  // let user = JSON.parse(userCookie)
  // console.log(userCookie)
  // console.log(user)
  if (typeof userCookie !== 'undefined' && userCookie !== 'false' && userCookie !== null && userCookie !== 'null') {
    next()
  } else {
    next({ name: 'Login' })
  }
}

function checkLoginAuth(to, from, next) {
  console.log('check login Auth')
  let userCookie = Vue.cookie.get('user')
  // console.log(userCookie)
  if (typeof userCookie !== 'undefined' && userCookie !== 'false' && userCookie !== null && userCookie !== 'null') {
    let user = JSON.parse(userCookie)
    for (let i = 0; i < user.roles.length; i++) {
      if (user.roles[i] == 'user' || user.roles[i] == 'sale') {
        next({ name: 'Sale' })
      } else if (user.roles[i] == 'web_admin') {
        next({ name: 'Admin' })
      } else if (user.roles[i] == 'admin_page') {
        next({ name: 'CreateTransaction' })
      } else if (user.roles[i] == 'super_admin') {
        next({ name: 'SuperAdmin' })
      }
    }
  } else {
    console.log('not log in')
    next()
  }
}

export default router
