//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'App',
  mounted () {
    this.init()
  },
  methods: {
    init() {
      let user_cookie = this.$cookie.get('user');
      this.user = JSON.parse(user_cookie)
      this.sale = false
      this.admin = false
      if (this.user) {
        this.full_name = this.user.email
        this.uid = this.user.uid
        for (let i = 0; i < this.user.roles.length; i++) {
          if (this.user.roles[i] == 'user' || this.user.roles[i] == 'sale') {
            this.sale = true
          }
          if (this.user.roles[i] == 'web_admin') {
            this.admin = true
          }
        }
      }
      console.log(this.user)
      console.log(this.sale)
    },
    logout() {
      // console.log(this.$cookie.get('user'));
      this.$cookie.delete('user')
      // console.log(this.$cookie.get('user'));
      this.$router.push({
        name: 'Login'
      })
    },
    go_to_sale() {
      this.$router.push('/sale')
    },
    go_to_admin() {
      this.$router.push('/admin')
    },
    go_to_dashboard() {
      this.$router.push('/dashboard')
    },
  },
  watch: {
    '$route' () {
      this.init()
    }
  },
  data: () => ({
    user: {},
    full_name: '',
    uid: -1,
    sale: false,
    admin: false,
  }),
};
